<template>
  <div>
    <c-search-box @enter="getDataList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="useFlagItems"
            label="LBLUSEFLAG"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            type="search"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="폐기물 종류 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getDataList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="폐기물 종류명"
                  name="envWasteMstName"
                  v-model="data.envWasteMstName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  type="number"
                  label="LBLSORTORDER"
                  name="sortOrder"
                  v-model="data.sortOrder">
                </c-text>
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable && dataeditable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-12">
                <c-table
                  ref="gridUnit"
                  title="폐기물 단가(년도별 톤당 단가)"
                  :columns="gridUnit.columns"
                  :data="data.unitPrices"
                  :editable="editable && dataeditable"
                  gridHeight="400px"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="envWasteUnitPriceId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && dataeditable" label="LBLEXCEPT" icon="remove" @btnClicked="deleteTable" />
                      <c-btn v-if="editable && dataeditable" label="LBLADD" icon="add" @btnClicked="addTable" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'waste-master-01',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      data: {
        envWasteMstId: '',  // 폐기물 종류 일련번호
        envWasteMstName: '',  // 폐기물 명
        sortOrder: null,  // 순번
        useFlag: 'Y',  // 사용여부
        plantCd: null,
        unitPrices: [],
        removeUnitPrices: [],
      },
      selectedEnvWasteMstId: '',
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      gridUnit: {
        columns: [
          {
            required: true,
            name: 'year',
            field: 'year',
            label: '년도',
            align: 'center',
            style: 'width:50%',
            sortable: false,
            type: 'date',
            dateType: 'year',
          },
          {
            required: true,
            name: 'unitPrice',
            field: 'unitPrice',
            label: '단가(톤당/원)',
            align: 'center',
            style: 'width:50%',
            sortable: false,
            type: 'number',
          },
        ],
        data: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 130px',
            sortable: true,
          },
          {
            name: 'envWasteMstName',
            field: 'envWasteMstName',
            label: '폐기물 종류명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width: 80px',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      saveable: false,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.env.waste.mst.insert.url,
      saveType: 'POST',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.env.waste.mst.list.url;
      this.detailUrl = selectConfig.env.waste.mst.get.url;
      this.insertUrl = transactionConfig.env.waste.mst.insert.url;
      this.updateUrl = transactionConfig.env.waste.mst.update.url;
      // list scope
      this.getDataList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDataList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
    },
    rowClick(row) {
      this.selectedEnvWasteMstId = row.envWasteMstId;
      // 상세조회
      this.saveable = true;
      this.updateMode = true;
      this.$http.url = this.detailUrl;
      this.$http.param = {envWasteMstId: row.envWasteMstId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData() {
      this.selectedEnvWasteMstId = '';
      this.saveable = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        envWasteMstId: '',  // 폐기물 종류 일련번호
        envWasteMstName: '',  // 폐기물 명
        sortOrder: null,  // 순번
        useFlag: 'Y',  // 사용여부
        plantCd: this.searchParam.plantCd,
        unitPrices: [],
        removeUnitPrices: [],
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.selectedEnvWasteMstId = '';
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        envWasteMstId: '',  // 폐기물 종류 일련번호
        envWasteMstName: '',  // 폐기물 명
        sortOrder: null,  // 순번
        useFlag: 'Y',  // 사용여부
        plantCd: null,
        unitPrices: [],
        removeUnitPrices: [],
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.data.unitPrices)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDataList();
    },
    addTable() {
      this.data.unitPrices.splice(0, 0, {
        envWasteUnitPriceId: '',
        envWasteMstId: this.selectedEnvWasteMstId,
        year: '', 
        unitPrice: null,
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      })
    },
    deleteTable() {
      let selectData = this.$refs['gridUnit'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.data.removeUnitPrices) this.data.removeUnitPrices = [];
            this.$_.forEach(selectData, item => { 
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.data.removeUnitPrices, { envWasteUnitPriceId: item.envWasteUnitPriceId })) {
                  this.data.removeUnitPrices.push(item)
              }
              this.data.unitPrices = this.$_.reject(this.data.unitPrices, item);
            })
            this.$refs['gridUnit'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
